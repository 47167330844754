// extracted by mini-css-extract-plugin
export var alignLeft = "s_qh d_bG";
export var alignCenter = "s_bP d_bD";
export var alignRight = "s_qj d_bH";
export var textAlignLeft = "s_rX";
export var textAlignCenter = "s_rY";
export var textAlignRight = "s_rZ";
export var embedInnerWrapperDesign1 = "s_r0 d_bM";
export var embed = "s_r1 d_b1";
export var titleMargin = "s_r2 d_cw d_dw";
export var subtitleMargin = "s_r3 d_cs d_dw";
export var paragraphMargin = "s_r4 d_cw d_dw";
export var SubtitleSmall = "s_qd C_qd C_tp C_tB";
export var SubtitleNormal = "s_qf C_qf C_tp C_tC";
export var SubtitleLarge = "s_qg C_qg C_tp C_tD";
export var BodySmall = "s_r5 C_r5 C_tp C_tJ";
export var BodyNormal = "s_r6 C_r6 C_tp C_tK";
export var BodyLarge = "s_r7 C_r7 C_tp C_tL";