// extracted by mini-css-extract-plugin
export var customText = "r_rQ d_dv d_cs d_cg";
export var videoIframeStyle = "r_pS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "r_rR d_cs d_cg d_Z";
export var customRow = "r_qb d_bD d_bf";
export var quoteWrapper = "r_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "r_pX d_H";
export var masonryImageWrapper = "r_p3";
export var title = "r_rS";
export var Title3Small = "r_rT C_rT C_tp C_tx";
export var Title3Normal = "r_rV C_rV C_tp C_ty";
export var Title3Large = "r_rW C_rW C_tp C_tz";