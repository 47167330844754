// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "y_s8 d_bD";
export var storyRowWrapper = "y_hx d_hx d_bK";
export var storyLeftWrapper = "y_s9 d_bz d_bP";
export var storyWrapperFull = "y_tb d_cD";
export var storyWrapperFullLeft = "y_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "y_mv d_hy";
export var storyLeftWrapperCenter = "y_tc d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "y_td d_hF";
export var storyHeader = "y_tf d_hD d_w d_cs";
export var storyHeaderCenter = "y_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "y_hB d_hB d_by d_dw";
export var storyBtnWrapper = "y_tg d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "y_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "y_th d_fg d_Z";
export var imageWrapperFull = "y_tj d_w d_H d_bf d_Z";
export var SubtitleSmall = "y_qd C_qd C_tp C_tB";
export var SubtitleNormal = "y_qf C_qf C_tp C_tC";
export var SubtitleLarge = "y_qg C_qg C_tp C_tD";
export var textLeft = "y_dv";
export var textCenter = "y_dw";
export var textRight = "y_dx";