// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_sN d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_sP d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_sQ d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_sR d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_sS d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_sT d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_sV d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_sW C_sW";
export var heroExceptionNormal = "w_sX C_sX";
export var heroExceptionLarge = "w_sY C_sY";
export var Title1Small = "w_sZ C_sZ C_tp C_tq";
export var Title1Normal = "w_s0 C_s0 C_tp C_tr";
export var Title1Large = "w_s1 C_s1 C_tp C_ts";
export var BodySmall = "w_r5 C_r5 C_tp C_tJ";
export var BodyNormal = "w_r6 C_r6 C_tp C_tK";
export var BodyLarge = "w_r7 C_r7 C_tp C_tL";